<template>
  <v-card
    class="elevation-0 album-form mx-auto"
    outlined
  >
    <HeaderCard>
      <v-icon
        class="mr-1"
        size="23px"
      >
        mdi-music-circle-outline
      </v-icon>
      <b>{{ getTitle }}</b>
    </HeaderCard>

    <v-card-text>
      <v-form
        ref="form"
        autocomplete="off"
      >
        <v-container
          grid-list-xl
          class="pt-0"
        >
          <v-layout
            v-if="isLoading"
            wrap
            align-center
            justify-center
          >
            <v-progress-circular
              :size="55"
              indeterminate
              color="primary"
              class="mx-auto my-7"
            />
          </v-layout>

          <v-layout
            v-else
            wrap
            class="px-3 qy-0"
          >
            <v-flex
              xs12
              sm5
              md5
            >
              <FieldText
                :readonly="!showEditFields"
                label="Nome do Album"
                v-model="albumName"
              />
            </v-flex>

            <v-flex
              xs12
              sm5
              md5
            >
              <FieldText
                :readonly="!showEditFields"
                label="Descrição"
                v-model="albumDescription"
              />
            </v-flex>

            <v-flex
              xs12
              sm5
              md5
            >
              <v-row>
                <v-img
                  class="mx-4"
                  :src="imageUrl"
                  max-width="80"
                  height="80"
                />
                <v-file-input
                  class="input-file mt-3"
                  v-model="file"
                  chips
                  accept="image/*"
                  label="Alterar imagem do Álbum"
                  @change="onFileChange"
                />
              </v-row>
            </v-flex>

            <v-flex
              class="pb-1 mt-3"
              xs12
              sm7
              md7
            >
              <FieldText
                label="Resumo"
                v-model="albumResume"
                :readonly="!showEditFields"
              />
            </v-flex>

            <v-flex
              xs12
              class="pt-10"
            >
              <div
                class="overflow-y-auto"
              >
                <DraggableAlbumTable
                  v-model="tracks"
                  :album-id="album.id"
                  :show-edit-fields="showEditFields"
                  :album-tracks="album.tracks"
                />
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </v-form>
    </v-card-text>

    <v-divider
      v-if="!isLoading"
    />

    <v-card-actions style="padding-top: 0.7em; padding-bottom: 0.7em;">
      <v-layout
        wrap
        justify-space-around
      >
        <v-btn
          color="blue darken-1"
          text
          @click="goBack"
        >
          Voltar
        </v-btn>
        <v-btn
          v-if="!isLoading && showEditFields"
          color="success"
          small
          @click="handleSave"
          depressed
          :disabled="false"
        >
          Salvar álbum
        </v-btn>
      </v-layout>
    </v-card-actions>
  </v-card>
</template>

<script>
import FieldText from '@/components/fields/FieldText'
import DraggableAlbumTable from '@/components/DraggableAlbumTable'
import HeaderCard from '@/components/HeaderCard'
import { mapState } from 'vuex'
import { requests } from '@/plugins/Amplify'
import { ROUTE_ALBUMS_NEW, ROUTE_ALBUMS_VIEW } from '@/constants'

export default {
  components: {
    FieldText,
    HeaderCard,
    DraggableAlbumTable
  },

  data () {
    const defaultImg = 'https://udv-mdt.s3.amazonaws.com/assets/images/generico.png'
    return {
      albumName: '',
      albumResume: '',
      albumDescription: '',
      tracks: [],
      file: null,
      imageUrl: defaultImg,
      defaultImg,
      isLoading: false
    }
  },

  mounted () {
    if (this.$route.name !== ROUTE_ALBUMS_NEW) this.getAlbumPage()
  },

  computed: {
    ...mapState({
      album: ({ albums }) => albums.row,
      status: ({ albums }) => albums.status
    }),

    getTitle () {
      const title = this.album.id ? 'title.updateAlbum' : 'title.newAlbum'
      return title.translate()
    },

    showEditFields () {
      return this.$route.name !== ROUTE_ALBUMS_VIEW
    },

    missingArgs () {
      return (
        !String(this.albumName || '').trim() ||
        !String(this.albumDescription || '').trim() ||
        !String(this.isLoading || '').trim()
      )
    }
  },

  methods: {
    goBack () {
      this.$router.go(-1)
    },

    getAlbumPage () {
      this.$store.dispatch('loadAlbum', this.$route.params)
    },

    async handleSave  () {
      this.isLoading = true

      const sameImage = this.album.imagem === this.imageUrl
      const name = this.album?.imagem?.split('/').at(-1) || null
      const equalFileName = name === this.file?.name
      const ignoreUpload = sameImage || equalFileName || !this.file

      if (ignoreUpload) return this.postAlbum(ignoreUpload)

      const signResult = await requests.getImageUploadUrl({ name: this.file.name })
      if (signResult?.url) return this.uploadFile(signResult)

      this.$store.dispatch('error')
    },

    uploadFile (signResult) {
      const vm = this
      const { fields, url } = signResult
      vm.imageUrl = `${url}/${fields.key}`

      const formData = new FormData()
      Object.entries(signResult.fields).forEach(([k, v]) => formData.append(k, v))
      formData.append('file', vm.file)

      const xhr = new XMLHttpRequest()
      xhr.open('POST', signResult.url, true)
      xhr.upload.addEventListener('loadend', vm.postAlbum(), false)
      xhr.send(formData)
    },

    postAlbum (ignoreUpload) {
      const vm = this
      const image = ignoreUpload ? (vm.imageUrl === vm.defaultImg ? '' : vm.album.imagem) : vm.imageUrl
      const payload = {
        nome: vm.albumName,
        resumo: vm.albumResume,
        descricao: vm.albumDescription,
        imagem: image,
        tracks: vm.tracks,
        ...vm.handleRemovedTracks()
      }

      if (vm.$route.params?.id) payload.id = vm.$route.params.id
      vm.$store.dispatch('saveAlbum', payload)
      vm.isLoading = false
    },

    onFileChange () {
      const reader = new FileReader()
      if (!this.file) {
        this.imageUrl = this.defaultImg
        return
      }

      reader.readAsDataURL(this.file)
      reader.onload = e => {
        this.imageUrl = e.target.result
      }
    },
    handleRemovedTracks () {
      const audiosIds = this.tracks.map(({ audioId }) => audioId)
      const removedTracks = this.album?.tracks?.filter(({ audioId }) => !audiosIds.includes(audioId))
      return { removedTracks }
    }
  },

  watch: {
    status: {
      deep: true,
      handler (status) {
        const vm = this

        if (status === 'STATUS_LOADING') vm.isLoading = true
        if (status === 'STATUS_ERROR') vm.isLoading = false

        if (status === 'STATUS_LOADED') {
          this.$nextTick(() => {
            Object.assign(vm, {
              isLoading: false,
              albumName: vm.album.nome,
              albumResume: vm.album.resumo,
              tracks: JSON.parse(JSON.stringify(vm.album.tracks)),
              albumDescription: vm.album.descricao,
              file: getFile(vm.album.imagem),
              imageUrl: vm.album.imagem || vm.defaultImg
            })
          })
        }

        if (status === 'STATUS_SAVED') {
          vm.goBack()
          vm.$notify({
            type: 'success',
            title: 'Sucesso!',
            text: 'Álbum cadastrado.',
            duration: 7000
          })
        }
      }
    }
  }
}

const getFile = (image) => {
  if (!image) return null
  const name = image.split('/').at(-1)
  return { name }
}
</script>

<style>
  .album-form tr.odd>td {
    background-color: #0094d90f
  }

  .input-file {
    max-width: 360px;
    align-items: end;
  }

  .input-file .v-text-field__details {
    display: none !important;
    min-height: 0px !important;
}
</style>
