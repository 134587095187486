<template>
  <div>
    <v-list-item dense>
      <v-list-item-avatar>
        <AudioPlayItem
          :id="id"
          :audio-type="audioType"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ assunto }}</v-list-item-title>
        <v-list-item-subtitle>{{ `${durationFormated} - ${oradorNome}` }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-content v-if="resumo">
        <v-list-item-subtitle>{{ resumo }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-spacer />

      <v-btn
        small
        color="primary"
        :disabled="isNewAudio"
        @click="$emit('add')"
      >
        <v-icon small>
          mdi-plus
        </v-icon>
        Adicionar
      </v-btn>
    </v-list-item>
    <v-divider />
  </div>
</template>
<script>
import { formatSecs } from '@/utils'
import AudioPlayItem from './AudioPlayItem'

export default {
  components: {
    AudioPlayItem
  },

  props: {
    id: {},
    oradorNome: {},
    assunto: {},
    duracao: {},
    audioType: {},
    resumo: {},
    trackList: {}
  },

  computed: {
    durationFormated () {
      return formatSecs(this.duracao)
    },

    isNewAudio () {
      return Boolean(this.trackList.find(({ audioId, id }) => [audioId, id].includes(this.id)))
    }
  }
}
</script>
