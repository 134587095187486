<template>
  <div>
    <audio
      preload="none"
      :id="elementId"
      :src="getUrl"
      :type="audioType"
      @ended="ended"
    />

    <v-container @click="toggleAudio">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-icon
            v-on="on"
            :size="25"
            v-if="playing"
            :disabled="loading"
            color="red lighten-1"
          >
            mdi-pause-circle-outline
          </v-icon>
          <v-icon
            v-on="on"
            :size="25"
            v-else
            :disabled="loading"
            color="blue darken-2"
          >
            mdi-play-circle-outline
          </v-icon>
        </template>

        <span v-if="playing">
          Pausar áudio
        </span>
        <span v-else>
          Ouvir áudio
        </span>
      </v-tooltip>
    </v-container>
  </div>
</template>
<script>
import { requests } from '@/plugins/Amplify'

export default {
  props: [
    'id',
    'audioType'
  ],

  data () {
    return {
      loading: true,
      getUrl: '',
      playing: false
    }
  },

  mounted () {
    this.makePlayerData()
  },

  computed: {
    elementId () {
      return `audio-player${this.id}`
    }
  },

  methods: {
    async toggleAudio  () {
      var audio = document.getElementById(`audio-player${this.id}`)
      if (audio.paused) {
        const promisePlay = audio.play()
        this.playing = true

        if (promisePlay !== undefined) {
          await promisePlay
        }

        return
      }

      audio.pause()
      this.playing = false
    },

    ended () {
      this.playing = false
    },

    async makePlayerData () {
      if (!this.id) return ''

      const { url } = await requests.getAudioStreamLink({ id: this.id })
      this.loading = false
      this.getUrl = url
    }

  }
}
</script>
