<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <v-dialog
          v-model="dialog"
          max-width="1000px"
        >
          <template v-slot:activator="{props}">
            <v-btn
              v-if="showEditFields"
              v-on="props"
              color="success"
              small
              @click="dialog = true"
            >
              <v-icon>mdi-music-note-plus</v-icon>
            </v-btn>
          </template>
          <v-card
            class="elevation-0"
            outlined
          >
            <v-container>
              <HeaderCard>
                <span class="text-6 ">Buscar Áudios</span>
              </HeaderCard>

              <v-card-text>
                <v-container grid-list-xl>
                  <v-layout
                    wrap
                    row
                    align-center
                  >
                    <v-flex
                      xs12
                      sm4
                    >
                      <FilterText
                        label="Assunto/Resumo/Descrição"
                        v-model="filters.assunto"
                      />
                    </v-flex>

                    <v-flex
                      xs12
                      sm4
                    >
                      <FilterTipo v-model="filters.tipo" />
                    </v-flex>

                    <v-flex
                      xs12
                      sm4
                    >
                      <FilterOrador
                        label="Orador"
                        v-model="filters.orador"
                      />
                    </v-flex>

                    <v-flex
                      v-if="count"
                      xs12
                      class="mr-0 mt-0 mb-0 pr-0 pt-0 pb-2 pl-2"
                    >
                      <v-chip
                        small
                        color="primary"
                        class="ma-1 pr-2"
                        label
                        outlined
                      >
                        {{ count === 1 ? 'um áudio encontrado' : '' }}{{ count > 1 ? `${count} áudios encontrados` : '' }}
                      </v-chip>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-list
                  dense
                  two-line
                  max-height="300px"
                >
                  <v-list-item-group
                    v-model="indexItem"
                    color="primary"
                    no-action
                  >
                    <v-divider />
                    <AlbumTrackListItem
                      v-for="(audio, index) in rows"
                      :key="index"
                      :id="audio.id"
                      :orador-nome="audio.oradorNome"
                      :assunto="audio.assunto"
                      :duracao="audio.duracao"
                      :resumo="audio.resumo"
                      :audio-type="audio.arquivoType"
                      :track-list="trackList"
                      @add="$emit('add', audio)"
                    />
                  </v-list-item-group>
                </v-list>
              </v-card-text>

              <v-divider />

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  outlined
                  @click="dialog = false"
                >
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <span> Adicionar um áudio</span>
  </v-tooltip>
</template>

<script>
import HeaderCard from '@/components/HeaderCard'
import FilterTipo from '@/components/filters/FilterTipo'
import FilterText from '@/components/filters/FilterText'
import FilterOrador from '@/components/filters/FilterOrador'
import { mapState } from 'vuex'

import AlbumTrackListItem from '@/views/Dashboard/AlbumTrackListItem'
import {
  ACTION_AUDIO_SEARCH
} from '@/constants'

export default {
  components: {
    FilterTipo,
    FilterText,
    FilterOrador,
    HeaderCard,
    AlbumTrackListItem
  },

  props: ['trackList', 'showEditFields'],

  data () {
    return {
      dialog: false,
      assunto: '',
      tipo: '',
      orador: '',
      filters: {},
      indexItem: -1
    }
  },

  computed: {
    ...mapState({
      rows: ({ searchAudios }) => searchAudios.rows,
      count: ({ searchAudios }) => searchAudios.count
    })
  },

  methods: {
    listTracks () {
      const params = {
        sortBy: 'id',
        order: 'ASC',
        limit: 9999
      }

      if (this.filters.assunto) params.assunto = this.filters.assunto
      if (this.filters.tipo) params.tipo = this.filters.tipo
      if (this.filters.orador) params.orador = this.filters.orador

      this.$store.dispatch(ACTION_AUDIO_SEARCH, params)
    }
  },

  watch: {
    filters: {
      deep: true,
      handler () {
        this.listTracks()
      }
    },

    dialog (dialog) {
      if (dialog) return this.listTracks()
      this.$emit('on-close')
    }

  }
}
</script>

<style scoped>
.v-list {
  display: flex !important;
  flex-direction: column;
  overflow: auto;
}

.v-list::-webkit-scrollbar {
  display: none;
}

</style>
